import React from 'react'
import Store from '../MyStore'
import { hadUserActivity } from '../Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'

function Viewer() {
  const store = Store.useStore()

  const viewerId = store.get('viewerId')
  const setViewerId = store.set('viewerId')

  const prevId = store.get('derived/viewerIdPrev')
  const nextId = store.get('derived/viewerIdNext')

  const showSensitiveContent = store.get('showSensitiveContent')

  const prev = () => {
    setViewerId(nextId)
    hadUserActivity()
  }

  const next = () => {
    setViewerId(prevId)
    hadUserActivity()
  }

  const close = () => {
    setViewerId(null)
    hadUserActivity()
  }

  if (!viewerId) {
    return null
  }

  const fragment = store.get('derived/viewerFragments').filter(f => f.id === viewerId)[0]
  if(!fragment) {
    return null
  }

  const isHidden = fragment.content_type.startsWith('hidden-') && !showSensitiveContent
  const isImage = fragment.content_type.startsWith('hidden-image') || fragment.content_type.startsWith('image')
  const isVideo = fragment.content_type.startsWith('hidden-video') || fragment.content_type.startsWith('video')

  return (
    <div className="modal is-active viewer">
      <div className="modal-background" />
      {!nextId || <FontAwesomeIcon className="viewer-prev" icon={faChevronCircleLeft} onClick={prev} />}
      <div className="modal-content">
        <p className={`image${ isHidden ? " blurred" : ""}`}>
          {!isImage || <img src={`/upload/${fragment.filename}`} alt="" />}
          {!isVideo || <video controls preload="metadata"
            className={`message-image${ isHidden && !showSensitiveContent ? ' blurred' : ''}`}
          >
            <source src={`/upload/${fragment.filename}`} />
          </video>}

        </p>
      </div>
      {!prevId || <FontAwesomeIcon className="viewer-next" icon={faChevronCircleRight} onClick={next} />}
      <button className="modal-close is-large" aria-label="close" onClick={close} />
    </div>
  )
}

export { Viewer }
