import { combineLatest, timer, interval } from 'rxjs'
import { webSocket } from 'rxjs/webSocket'
import { debounce, map, filter, throttle } from 'rxjs/operators'
import { Message, StoreEffects, DEFAULT_AUTO_LOGOUT_TIME } from './MyStore'
import { getCookies, jsonToMessages, setAutoLogoutTimer } from './Utils'

let effects: StoreEffects = store => {
  combineLatest(store.on('myId'), store.on('pendingMessages'))
    .pipe(
      map(([myId, pendingMessages]) => pendingMessages.map(msg => ({
        created: msg.created,
        received: null,
        sender: myId,
        fragments: [{
          content_type: 'text/plain',
          content: msg.content,
        }],
        receipt: [],
      })))
    )
    .subscribe(store.set('derived/pendingMessages'))

  combineLatest(store.on('messages'), store.on('derived/pendingMessages'), store.on('receipts'))
    .pipe(
      map( ([messages, pendingMessages, receipts]) => 
        [...messages, ...pendingMessages].map(message => {
            const { receipt, ...props } = message
            return { ...props, receipt: [...receipt, ...receipts.filter(r => r.message === message.id)] }
          })
          .sort((x, y) => x.created.getTime() - y.created.getTime())
      )
    )
    .subscribe(store.set('derived/messages'))

  combineLatest(store.on('myId'), store.on('messages'), store.on('receipts'), store.on('pincode'))
    .pipe(
      filter(([myId, messages, receipts, pincode]) => myId !== 0 && !!pincode),
      map(([myId, messages, receipts, pincode]) => {
        const myReceipts = receipts.filter(r => r.recipient === myId)

        const pendingReceipt = messages
          // If I didn't send the message
          .filter(m => m.sender !== myId)
          // And if there are no read receipts for me
          .filter(m => (m.receipt || []).filter(r => r.recipient === myId).length === 0)
          // And there are no read receipts for me here either
          .filter(m => myReceipts.filter(r => r.message === m.id && r.recipient === myId).length === 0)
          .map(m => m.id)
        return [pendingReceipt, pincode]
      }),
      filter(([pendingReceipt, pincode]) => !!pendingReceipt && pendingReceipt.length > 0),
      debounce(() => timer(100)),
    )
    .subscribe(([needReceipts, pin]) => {
      fetch(`/api/messages/receipt/`,
        {
          credentials: 'include',
          mode: 'cors',
          method: 'POST',
          headers: {
            'X-CSRFToken': getCookies()['csrftoken'],
            'Authorization': `Guava-Pincode ${pin}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(needReceipts),
        })
    })

  const fetchMedia = (pin: string, fetchHiddenContent: boolean) =>
    fetch(`/api/fragments/?media=image,video${fetchHiddenContent ? ',hidden-image,hidden-video' : ''}`, {
        credentials: 'include',
        headers: {
          'Authorization': `Guava-Pincode ${pin}`,
        },
      })
      .then(response => response.json())
      .then(json => {
        if(json) {
          store.set('mediaFragments')(json)
        }
      })
      .catch(err => {
        console.log(err.message)
      })

  const fetchMessages = (url: string, pin: string) =>
    fetch(url, {
        credentials: 'include',
        headers: {
          'Authorization': `Guava-Pincode ${pin}`,
        },
      })
      .then(response => response.json())
      .then(json => {
        if(json.results) {
          const messages = store.get('messages')
          const myId = store.get('myId')
          const ids = new Set(messages.map(x => x.id))
          const newMessages = jsonToMessages(json.results)
          const maxId = store.get('derived/maxId')
          const audibleAlert = store.get('audibleAlert')
          if(audibleAlert && newMessages.filter((m: Message) => m.id !== myId).length > 0) {
            if(newMessages[newMessages.length - 1].id > maxId) {
              store.set('messageAlert')(Date.now())
            }
          }
          store.set('messages')([
            ...messages,
            ...newMessages.filter((m: Message) => !ids.has(m.id))
          ])
        }
      })
      .catch(err => {
        console.log(err.message)
      })

  const fetchRecentMessages = (pin: string) => fetchMessages('/api/messages/?limit=50', pin)

  combineLatest(store.on('fetchingBefore'), store.on('derived/minId'), store.on('pincode'))
    .pipe(
      debounce(() => timer(1000)),
      filter(([fetchingBefore, minId, pincode]) => !!pincode),
    )
    .subscribe(([fetchingBefore, minId, pincode]) => {
      console.log('fetchingBefore: ' + fetchingBefore + ', minId: ' + minId)
      if(!!fetchingBefore && fetchingBefore < minId && !!pincode) {
        fetchMessages(`/api/messages/?before=${fetchingBefore}`, pincode)
          .then(() => store.set('fetchingBefore')(null))
      }
    })

  combineLatest(store.on('showSensitiveContent'), store.on('mediaFragments'), store.on('derived/messages').pipe(
        map(messages => messages.flatMap(m => m.fragments)
          /*
          .filter(fragment => (!showSensitiveContent) ? (fragment.content_type.startsWith('image/')
            || fragment.content_type.startsWith('video/'))
            : (fragment.content_type.startsWith('hidden-image/')
            || fragment.content_type.startsWith('hidden-video/'))
          )
          */
        )
      )
    )
    .pipe(
      map(([showSensitiveContent, mediaFragments, messageFragments]) => {
        const ids = new Set(mediaFragments.map(x => x.id))
        return [...mediaFragments, ...messageFragments.filter(x => !ids.has(x.id))].sort((x, y) => (x.id || 0) - (y.id || 0))
          .filter(fragment => (!showSensitiveContent) ? (fragment.content_type.startsWith('image/')
            || fragment.content_type.startsWith('video/'))
            : (fragment.content_type.startsWith('hidden-image/')
            || fragment.content_type.startsWith('hidden-video/'))
          )
      })
    )
    .subscribe(store.set('derived/viewerFragments'))

  combineLatest(store.on('viewerId'), store.on('derived/viewerFragments'))
    .pipe(
      map(([viewerId, viewerFragments]) => {
        var before = null, after = null
        for(var i = 0; i < viewerFragments.length; ++i) {
          if(viewerFragments[i].id === viewerId) {
            if(i > 0) {
              before = viewerFragments[i - 1].id || null
            }
            if(i < viewerFragments.length - 1) {
              after = viewerFragments[i + 1].id || null
            }
            break
          }
        }
        return [before, after]
      })
    )
    .subscribe(([before, after]) => {
      store.set('derived/viewerIdPrev')(before)
      store.set('derived/viewerIdNext')(after)
    })

  store.on('derived/viewerFragments')
    .pipe(
      map(fragments => (fragments.length > 0) ? fragments[fragments.length - 1].id || null : null)
    )
    .subscribe(store.set('derived/viewerIdMax'))

  combineLatest(store.on('myId'), store.on('pincode'), store.on('showSensitiveContent'))
    .pipe(
      filter(([myId, pincode, showSensitiveContent]) => !!myId && !!pincode)
    )
    .subscribe(([myId, pincode, showSensitiveContent]) => {
      if(!!pincode) {
        fetchRecentMessages(pincode)
        fetchMedia(pincode, showSensitiveContent)
      }
    })

  store.on('messages')
    .subscribe(messages => {
      const minId = messages.reduce((acc, cur) => Math.min(acc, cur.id || 9223372036854775807), 9223372036854775807)
      const maxId = messages.reduce((acc, cur) => Math.max(acc, cur.id || 0), 0)
      store.set('derived/minId')(minId)
      store.set('derived/maxId')(maxId)
    })

  combineLatest(store.on('fetchMessages'), store.on('derived/maxId'), store.on('pincode'))
    .pipe(
      filter(([fetchMessages, maxId, pincode]) => fetchMessages && !!pincode),
      map(([x, y, pincode]) => [y, pincode]),
    )
    .subscribe(([maxId, pincode]) => {
      console.log("Max ID: " + maxId)
      if(!!pincode) {
        fetchMessages(`/api/messages/?since=${maxId}`, pincode + '')
          .then(() => store.set('fetchMessages')(false))
      }
    })

  store.on('autoLogoutTime')
    .subscribe(timeInSeconds => setAutoLogoutTimer(timeInSeconds))

  store.set('showSensitiveContent')(false)
  store.set('pendingMessages')([])
  store.set('receipts')([])
  store.set('autoLogoutTime')(DEFAULT_AUTO_LOGOUT_TIME)

  return store
}

export default effects
