import React from 'react'
import Store, { AppPanel } from './MyStore'
import {
  SocketController, Messages, Keyboard, PinPad, NavBar, Viewer, Gallery,
  VideoCall, Settings, IncomingCallPanel,
} from './Components'

import './App.sass'

function App() {
  const store = Store.useStore()
  const activePanel = store.get('activePanel')
  const myId = store.get('myId')

  const viewerId = store.get('viewerId')
  const standalone = ((window.navigator || {}) as any).standalone

  if (myId === 0) {
    return <PinPad />
  }

  const renderActivePanel = () => {
    switch(activePanel) {
    case AppPanel.Chat:
      return <>
        <Messages />
        <Keyboard />
      </>
    case AppPanel.Gallery:
      return <Gallery />
    case AppPanel.Video:
      return <VideoCall />
    case AppPanel.Settings:
      return <Settings />
    }
    return null
  }

  return (
    <main className={standalone ? 'standalone' : ''}>
      <SocketController />
      {!viewerId || <Viewer />}
      <NavBar />
      {renderActivePanel()}
      <IncomingCallPanel />
    </main>
  )
}

function AppWrapper() {
  return (
    <Store.Container>
      <App />
    </Store.Container>
  )
}

export default AppWrapper
