import { Effects, Store, createConnectedStore } from 'undux'
import effects from './MyEffects'

export const DEFAULT_AUTO_LOGOUT_TIME = 60

export enum AppPanel {
  Chat,
  Gallery,
  Video,
  Settings,
}

export type MessageReceipt = {
  timestamp: Date
  recipient: number
  message: number
}

export type MessageFragment = {
  id?: number
  content_type: string
  content: any
  filename?: string | null
}

export type PendingMessage = {
  created: Date
  content: string
}

export type Message = {
  id?: number
  created: Date
  received: Date | null
  sender: number
  fragments: MessageFragment[]
  receipt: MessageReceipt[]
}

type State = {
  pincode: string | null
  myId: number

  activePanel: AppPanel

  messages: Message[]
  pendingMessages: PendingMessage[]
  fetchingBefore: number | null
  selectedMessage: number | null

  pendingFragments: MessageFragment[]
  fetchMessages: boolean
  receipts: MessageReceipt[]

  socket: WebSocket | null
  peerOnline: boolean
  peerTyping: boolean

  mediaFragments: MessageFragment[]
  viewerId: number | null

  autoLogoutTime: number
  showSensitiveContent: boolean
  audibleAlert: boolean

  messageAlert: number | null
  incomingCallId: number | null

  'derived/pendingMessages': Message[]
  'derived/messages': Message[]

  'derived/minId': number
  'derived/maxId': number

  'derived/viewerIdPrev': number | null
  'derived/viewerFragments': MessageFragment[]
  'derived/viewerIdNext': number | null
  'derived/viewerIdMax': number | null
}

let initialState: State = {
  pincode: null,
  myId: 0,

  activePanel: AppPanel.Chat,

  messages: [],
  pendingMessages: [],
  fetchingBefore: null,
  selectedMessage: null,

  pendingFragments: [],
  fetchMessages: false,
  receipts: [],

  socket: null,
  peerOnline: false,
  peerTyping: false,

  mediaFragments: [],
  viewerId: null,

  autoLogoutTime: DEFAULT_AUTO_LOGOUT_TIME,
  showSensitiveContent: false,
  audibleAlert: false,

  messageAlert: 0,
  incomingCallId: null,

  'derived/pendingMessages': [],
  'derived/messages': [],

  'derived/minId': 9223372036854775807,
  'derived/maxId': 0,

  'derived/viewerIdPrev': null,
  'derived/viewerFragments': [],
  'derived/viewerIdNext': null,
  'derived/viewerIdMax': null,
}

export default createConnectedStore(initialState, effects)

export type StoreProps = {
  store: Store<State>
}

export type StoreEffects = Effects<State>
