import React from 'react'
import Store from '../MyStore'

function Settings() {
  const store = Store.useStore()
  const timeout = store.get('autoLogoutTime')
  const setTimeout = store.set('autoLogoutTime')

  const sensitive = store.get('showSensitiveContent')
  const setSensitive = store.set('showSensitiveContent')

  const audibleAlert = store.get('audibleAlert')
  const setAudibleAlert = store.set('audibleAlert')

  return <section className="section settings-wrapper">
    <ul>
      <li className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Logout Timer</label>
        </div>

        <div className="field-body">
          <div className="field is-expanded">
            <div className="field has-addons">
              <div className="control is-expanded">
                <div className="tabs is-fullwidth is-toggle">
                  <ul>
                    <li className={timeout === 60 ? 'is-active' : ''}>
                      <a onClick={() => setTimeout(60)}>
                        <span>1 min</span>
                      </a>
                    </li>
                    <li className={timeout === 60 * 5 ? 'is-active' : ''}>
                      <a onClick={() => setTimeout(60 * 5)}>
                        <span>5 min</span>
                      </a>
                    </li>
                    <li className={timeout === 60 * 15 ? 'is-active' : ''}>
                      <a onClick={() => setTimeout(60 * 15)}>
                        <span>15 min</span>
                      </a>
                    </li>
                    <li className={timeout === 60 * 120 ? 'is-active' : ''}>
                      <a onClick={() => setTimeout(60 * 120)}>
                        <span>2 hr</span>
                      </a>
                    </li>
                  </ul>
                </div>

              </div>

            </div>
          </div>
        </div>

      </li>

      <li className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Sensitive content</label>
        </div>

        <div className="field-body">
          <div className="field is-expanded">
            <div className="field has-addons">
              <div className="control is-expanded">
                <div className="tabs is-fullwidth is-toggle">
                  <ul>
                    <li className={!sensitive ? 'is-active' : ''}>
                      <a onClick={() => setSensitive(false)}>
                        <span>Hide</span>
                      </a>
                    </li>
                    <li className={sensitive ? 'is-active show-sensitive' : ''}>
                      <a onClick={() => setSensitive(true)}>
                        <span>Show</span>
                      </a>
                    </li>
                  </ul>
                </div>

              </div>

            </div>
          </div>
        </div>

      </li>

      <li className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Audible Alert</label>
        </div>

        <div className="field-body">
          <div className="field is-expanded">
            <div className="field has-addons">
              <div className="control is-expanded">
                <div className="tabs is-fullwidth is-toggle">
                  <ul>
                    <li className={!audibleAlert ? 'is-active' : ''}>
                      <a onClick={() => setAudibleAlert(false)}>
                        <span>Off</span>
                      </a>
                    </li>
                    <li className={audibleAlert ? 'is-active show-sensitive' : ''}>
                      <a onClick={() => setAudibleAlert(true)}>
                        <span>On</span>
                      </a>
                    </li>
                  </ul>
                </div>

              </div>

            </div>
          </div>
        </div>

      </li>

    </ul>
  </section>
}


export { Settings }
