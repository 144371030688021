import React from 'react'
import { useEffect, useRef, useState } from 'react'
import Store from '../MyStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faTimes } from '@fortawesome/free-solid-svg-icons'

const PIN = '⬤'

type ButtonProps = {
  chr: string
  onClick: (chr: string) => void
}

function Button({chr, onClick}: ButtonProps) {
  return <button className="button is-rounded" onClick={() => onClick(chr)}>{chr}</button>
}

function PinPad() {
  const store = Store.useStore()

  const [pin, setPin] = useState('')
  const setPincode = store.set('pincode')
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const preventDefault = (e: Event) => {
      e.preventDefault()
    }
    const r = ref?.current
    r?.addEventListener('touchmove', preventDefault, { passive: false })
    return () => {
      r?.removeEventListener('touchmove', preventDefault)
    }
  })

  const pinMuted = Array.from(pin).map(c => PIN)
  const press = (chr: string) => setPin(pin + chr)

  const clear = () => setPin('')
  const login = () => {
    fetch('/api/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Guava-Pincode ${pin}`,
        },
      })
      .then(response => {
        if(!response.ok) {
          if(response.status === 403) {
            throw Error(response.statusText)
          }
        }
        return response.json()
      })
      .then(json => {
        setPincode(pin)
        setPin('')
        store.set('myId')(json['myId'])
      })
      .catch(error => setPin(''))
  }

  return <div className="container" ref={ref}>
    <div className="pinpad">
      <div className="pin-preview"><span>{pinMuted}</span></div>

      <div className="buttons">
        <div>
          <Button chr={'1'} onClick={press} />
          <Button chr={'2'} onClick={press} />
          <Button chr={'3'} onClick={press} />
        </div>
        <div>
          <Button chr={'4'} onClick={press} />
          <Button chr={'5'} onClick={press} />
          <Button chr={'6'} onClick={press} />
        </div>
        <div>
          <Button chr={'7'} onClick={press} />
          <Button chr={'8'} onClick={press} />
          <Button chr={'9'} onClick={press} />
        </div>
        <div>
          <Button chr={'*'} onClick={press} />
          <Button chr={'0'} onClick={press} />
          <Button chr={'+'} onClick={press} />
        </div>
        <div>
          <button className="button is-rounded is-warning" onClick={clear}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <button className="button is-rounded is-primary" onClick={login}>
            <FontAwesomeIcon icon={faPlay} />
          </button>
        </div>
      </div>
    </div>
  </div>
}

export { PinPad }
