import React from 'react'
import { useEffect, useRef } from 'react'
import Store from '../MyStore'
import { jsonToMessage } from '../Utils'

const WEBSOCKET_URL = `wss://${window.location.host}/ws/activity/`

const SocketController = () => {
  const store = Store.useStore()
  const setFetchMessages = store.set('fetchMessages')
  const setIncomingCallId = store.set('incomingCallId')

  const receipts = store.get('receipts')
  const setReceipts = store.set('receipts')
  const myId = store.get('myId')
  const setSocket = store.set('socket')
  const setPeerOnline = store.set('peerOnline')
  const setTyping = store.set('peerTyping')

  const sock = useRef<WebSocket | null>(null)

  const messages = store.get('messages')

  const handleMessage = (message: any) => {
    switch(message.type) {
    case 'new_message':
      setFetchMessages(true)
      break
    case 'read_receipt':
      setReceipts([...receipts, message.receipt])
      break
    case 'user_online':
      if(message.id !== myId) {
        setPeerOnline(true)
        sock?.current?.send(JSON.stringify({'type': 'hello'}))
      }
      break
    case 'hello':
      if(message.id !== myId) {
        setPeerOnline(true)
      }
      break
    case 'user_offline':
      if(message.id !== myId) {
        setPeerOnline(false)
      }
      break
    case 'message_removed':
      store.set('messages')(messages.filter(m => m.id !== message.id))
      break
    case 'invite':
      setIncomingCallId(message.call_id)
      break
    case 'message_updated':
      const newMessage = jsonToMessage(message.message)
      store.set('messages')([...messages.filter(m => m.id !== newMessage.id), newMessage])
      break
    case 'typing':
      if(myId !== message.id) {
        setTyping(true)
      }
      break
    case 'notTyping':
      if(myId !== message.id) {
        setTyping(false)
      }
      break
    default:
      console.log('unknown message type: ' + message.type)
    }
    console.log('TODO: process message: ' + JSON.stringify(message))
  }

  const keepAlive = () => {
    sock?.current?.send(JSON.stringify({'type': 'ping'}))
  }

  useEffect(() => {
    if(!sock.current) {
      sock.current = new WebSocket(WEBSOCKET_URL)
    }

    const newSock = sock.current
    newSock.onopen = () => {
      console.log('Socket opened')
      const ws = newSock as any
      ws.keepAliveTimer = setInterval(keepAlive, 45000)
      setSocket(newSock)
      setFetchMessages(true)
    }
    newSock.onclose = () => {
      console.log('Connection closed')
      const keepAliveTimer = sock.current ? (sock.current as any).keepAliveTimer : null || null;
      if(keepAliveTimer) {
        clearInterval(keepAliveTimer)
      }
      sock.current = null
      setTimeout(() => setSocket(null), 1000)
    }
    newSock.onmessage = (event: any) => {
      console.log('got message: ' + event.data)
      const data = JSON.parse(event.data)
      handleMessage(data)
    }
    newSock.onerror = () => console.log('Socket error')
  })

  return <></>
}

export { SocketController }
