import React from 'react'
import { useEffect, useRef, useState } from 'react'
import { Message } from './Message'
import { IncomingMessage } from './IncomingMessage'
import { hadUserActivity } from '../Utils'
import useSound from 'use-sound'
import Store from '../MyStore'

import newMessage from "./message.mp3"

function Messages() {
  const store = Store.useStore()
  const myId = store.get('myId')
  const minId = store.get('derived/minId')
  const maxId = store.get('derived/maxId')
  const messages = store.get('derived/messages')
  const [newestId, setNewestId] = useState(0)
  const peerTyping = store.get('peerTyping')

  const messageRef = useRef<HTMLDivElement>(null)
  const bottomRef = useRef<HTMLDivElement>(null)
  const fetchingBefore = store.get('fetchingBefore')
  const setFetchingBefore = store.set('fetchingBefore')
  const messageAlert = store.get('messageAlert')

  const finishedPlaying = () => store.set('messageAlert')(null)
  const [playNewMessage] = useSound(newMessage, { onend: finishedPlaying })

  useEffect(() => {
    let cleanup = undefined
    const onScroll = (e: any) => {
      if(e.target.scrollTop === 0) {
        if (fetchingBefore === null || minId < fetchingBefore) {
          setFetchingBefore(minId - 1)
        }
      }
      hadUserActivity()
    }
    if (messageRef && messageRef.current) {
      messageRef.current.addEventListener('scroll', onScroll, false)
      cleanup = () => window.removeEventListener('scroll', onScroll)
    }

    if (maxId > newestId) {
      if(bottomRef && bottomRef.current) {
        setNewestId(maxId)
        bottomRef.current.scrollIntoView(false)
      }
    }
    return cleanup
  }, [newestId, minId, maxId, fetchingBefore, setFetchingBefore])

  return <div ref={messageRef} className="messages">
    {messages.map((m, key) => <Message
      key={key}
      id={m.id || 9223372036854775807}
      created={m.created}
      received={m.received}
      sender={m.sender}
      content={m.fragments}
      receipt={m.receipt}
    />)}
    {!peerTyping || <IncomingMessage />}
    <div ref={bottomRef} className="message-bottom" />

    {!messageAlert || playNewMessage()}
  </div>
}

export { Messages }
