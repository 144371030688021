import React from 'react'
import Store, { AppPanel } from '../MyStore'
import { useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUserClock, faImages, faVideo, faCogs, faSatelliteDish } from '@fortawesome/free-solid-svg-icons'

function NavBar() {
  const ref = useRef<HTMLDivElement>(null)
  const store = Store.useStore()
  const activePanel = store.get('activePanel')
  const setActivePanel = store.set('activePanel')
  const peerOnline = store.get('peerOnline')
  const socket = store.get('socket')

  useEffect(() => {
    const preventDefault = (e: Event) => {
      e.preventDefault()
    }
    const r = ref?.current
    r?.addEventListener('touchmove', preventDefault, { passive: false })
    return () => {
      r?.removeEventListener('touchmove', preventDefault)
    }
  }, [])

  const statusIcon = socket ? (peerOnline ? faUser : faUserClock) : faSatelliteDish
  const statusClass = socket ? (peerOnline ? 'user-online' : 'user-offline') : 'connection-problem'

  const chatClass = activePanel === AppPanel.Chat ? 'is-active' : ''
  const galleryClass = activePanel === AppPanel.Gallery ? 'is-active' : ''
  const videoClass = activePanel === AppPanel.Video ? 'is-active' : ''
  const settingsClass = activePanel === AppPanel.Settings ? 'is-active' : ''

  const openChat = () => setActivePanel(AppPanel.Chat)
  const openGallery = () => setActivePanel(AppPanel.Gallery)
  const openVideo = () => setActivePanel(AppPanel.Video)
  const openSettings = () => setActivePanel(AppPanel.Settings)

  return <>
    <nav ref={ref} className="navbar" role="navigation" aria-label="main navigation">
      <div className="tabs is-large">
        <ul>
          <li className={chatClass}><a href="/#" onClick={openChat}><FontAwesomeIcon icon={statusIcon} className={statusClass} /></a></li>
          <li className={galleryClass}><a href="/#" onClick={openGallery}><FontAwesomeIcon icon={faImages} className="open-gallery" /></a></li>
          {/*<li className={videoClass}><a href="/#" onClick={openVideo}><FontAwesomeIcon icon={faVideo} className="open-video" /></a></li>*/}
          <li className={settingsClass}><a href="/#" onClick={openSettings}><FontAwesomeIcon icon={faCogs} className="open-settings" /></a></li>
        </ul>
      </div>
    </nav>
  </>
}

export { NavBar }
