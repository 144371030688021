import React, { useRef, useEffect } from 'react'
import Store from '../MyStore'
import moment from 'moment-timezone'
import { MessagePart } from './MessagePart'
import { MessageFragment, MessageReceipt } from '../MyStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { getCookies } from '../Utils'

const CHECK = <span>✓</span>
const CHECK2 = <span className="is-primary">✓</span>

type Props = {
  id: number
  created: Date
  received: Date | null
  sender: number
  content: MessageFragment[]
  receipt: MessageReceipt[]
}

function Message({id, created, received, sender, content, receipt}: Props) {
  const store = Store.useStore()
  const myId = store.get('myId')
  const pin = store.get('pincode')
  const selectedMessage = store.get('selectedMessage')
  const setSelectedMessage = store.set('selectedMessage')

  const className = (sender === myId ? 'from-us' : 'from-them') + ' ' +
      (id === selectedMessage ? 'message-selected' : '')

  const dateSpan = (d: Date, label: string = '') => {
    const t = moment(d)
    const includeTime = moment().unix() - t.unix() > 2640
    const date = (includeTime ? '~' : '') + t.fromNow() + (includeTime ? ' @ ' + t.format('h:mma') : '')

    if(sender !== myId) {
      return <span className="date">{label} {date}</span>
    }

    if (!received) {
      return <span className="button is-loading is-primary message-sending" />
    }

    if(receipt.length) {
      for(var i = 0; i < receipt.length; ++i) {
        if (receipt[i].recipient !== myId) {
          return <span className="date">{label} {date} {CHECK2}{CHECK2}</span>
        }
      }
    }
    return <span className="date">{label} {date} {CHECK}{CHECK2}</span>
  }

  const textContent = content.filter(f => f.content_type === 'text/plain')
  const otherContent = content.filter(f => f.content_type !== 'text/plain')

  const messagePart = ((f: MessageFragment, key: number) =>
    <MessagePart key={key} {...f} />
  )

  const timer = useRef<any>(null)
  useEffect(() => {
    return () => {
      if(timer.current) {
        clearInterval(timer.current)
      }
    }
  })

  const touchStart = () => {
    if(timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      setSelectedMessage(id)
      timer.current = null
    }, 600)
  }

  const touchEnd = () => {
    if(timer.current) {
      clearTimeout(timer.current)
      timer.current = null
    }
  }

  const dateStamp = () => {
    if (selectedMessage !== id) {
      return dateSpan(created)
    }

    const readDate = receipt.filter(r => r.recipient !== sender)

    return <>
      {dateSpan(created, 'Created')}
      {!!received ? dateSpan(received, 'Received') : <span className="date">Not received yet</span>}
      {readDate.length !== 0 || <span className="date">Not read yet</span>}
      {readDate.length === 0 || dateSpan(readDate[0].timestamp, 'Read')}
    </>
  }

  const confirmDelete = () => {
    if(window.confirm('Are you sure you wish to delete this message?')) {
      fetch(`/api/messages/${id}/`, {
          credentials: 'include',
          mode: 'cors',
          method: 'DELETE',
          headers: {
            'Authorization': `Guava-Pincode ${pin}`,
            'X-CSRFToken': getCookies()['csrftoken'],
          },
        })
        .then(response => {
          const messages = store.get('messages')
          store.set('messages')(messages.filter(m => m.id !== id))
        })
    }
  }

  const hideContent = () => {
    fetch(`/api/messages/${id}/hide-content/`, {
        credentials: 'include',
        mode: 'cors',
        method: 'POST',
        headers: {
          'Authorization': `Guava-Pincode ${pin}`,
          'X-CSRFToken': getCookies()['csrftoken'],
        },
      })
      .then(response => response.json())
      .then(json => {
        const messages = store.get('messages')
        store.set('messages')([...messages.filter(m => m.id !== id), json])
      })
  }

  const showContent = () => {
    fetch(`/api/messages/${id}/show-content/`, {
        credentials: 'include',
        mode: 'cors',
        method: 'POST',
        headers: {
          'Authorization': `Guava-Pincode ${pin}`,
          'X-CSRFToken': getCookies()['csrftoken'],
        },
      })
      .then(response => response.json())
      .then(json => {
        const messages = store.get('messages')
        store.set('messages')([...messages.filter(m => m.id !== id), json])
      })
  }

  const messageOptions = () => {
    const anyHidden = content.filter(f => f.content_type.startsWith('hidden-')).length > 0

    return <div className="message-options">
      {anyHidden || <button className="button is-info" onClick={hideContent}>
        <FontAwesomeIcon icon={faEye} />
      </button>}
      {!anyHidden || <button className="button is-warning" onClick={showContent}>
        <FontAwesomeIcon icon={faEyeSlash} />
      </button>}
      {sender === myId && <button className="button is-danger" onClick={confirmDelete}>
        <FontAwesomeIcon icon={faTrash} />
      </button>}
    </div>
  }

  return (
    <div className={`card message ${className}`} onMouseDown={touchStart} onMouseUp={touchEnd} onTouchStart={touchStart} onTouchEnd={touchEnd} onTouchCancel={touchEnd}>
      <div className="message-text">
        {textContent.map(messagePart)}
      </div>

      <div className="message-media">
        {otherContent.map(messagePart)}
      </div>
      {dateStamp()}

      {id === selectedMessage && messageOptions()}
    </div>
  )
}

export { Message }
