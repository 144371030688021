import React from 'react'
import Store from '../MyStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'

function IncomingMessage() {
	const store = Store.useStore()
	if(!store.get('peerOnline')) {
		return null
	}

  return (
    <div className="card message from-them incoming-message">
      <div className="message-text">
        <FontAwesomeIcon icon={faPencilAlt} className="writing" />
      </div>
    </div>
  )
}

export { IncomingMessage }
