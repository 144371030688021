import React from 'react'
import Store from '../MyStore'
import { MessagePart } from './MessagePart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faVideo } from '@fortawesome/free-solid-svg-icons'

function Gallery() {
  const store = Store.useStore()

  const mediaFragments = [...store.get('derived/viewerFragments')].reverse()

  return <div className="gallery">
    <ul className="gallery-content">
      {mediaFragments.map((x, idx) => <li key={idx}>
        <MessagePart key={idx} {...x} />
        <p className="gallery-label">{x.id}</p>
      </li>)}
    </ul>
  </div>
}

export { Gallery }
