import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume, faBan } from '@fortawesome/free-solid-svg-icons'
import { getCookies } from '../Utils'
import Store from '../MyStore'

function IncomingCallPanel() {
  const store = Store.useStore()

  const incomingCallId = store.get('incomingCallId')
  const setIncomingCallId = store.set('incomingCallId')

  if (incomingCallId === null) {
    return null
  }

  const acceptCall = () => {
    fetch(`/api/rtc/call/${incomingCallId}/accept/`, {
        credentials: 'include',
        mode: 'cors',
        method: 'POST',
        headers: {
          'X-CSRFToken': getCookies()['csrftoken'],
        },
      })
      .then(response => {
        setIncomingCallId(null)
      })
  }

  const rejectCall = () => {
    fetch(`/api/rtc/call/${incomingCallId}/reject/`, {
        credentials: 'include',
        mode: 'cors',
        method: 'POST',
        headers: {
          'X-CSRFToken': getCookies()['csrftoken'],
        },
      })
      .then(response => {
        setIncomingCallId(null)
      })
  }

  return <div className="incoming-call">
    <div>
      <button className="button answer" onClick={acceptCall}>
        <FontAwesomeIcon icon={faPhoneVolume} />
      </button>
      <button className="button hangup" onClick={rejectCall}>
        <FontAwesomeIcon icon={faBan} />
      </button>
    </div>
  </div>
}


export { IncomingCallPanel }
