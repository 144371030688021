import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilm } from '@fortawesome/free-solid-svg-icons'
import Store, { MessageFragment } from '../MyStore'
import { hadUserActivity } from '../Utils'

const MessagePart = ({content, content_type, filename, id}: MessageFragment) => {
  const store = Store.useStore()
  const mediaType = content_type.split('/')[0]
  const isHidden = content_type.startsWith('hidden-')
  const actualMediaType = isHidden ? mediaType.split('-')[1] : mediaType

  const showSensitiveContent = store.get('showSensitiveContent')
  const setViewerId = store.set('viewerId')

  const openViewer = (filename: string) => {
    setViewerId(id || null)
    hadUserActivity()
  }

  switch (actualMediaType) {
  case 'text':
    return <p className="message-text">{content}</p>
  case 'video':
    return !filename ? null : (
      <div onClick={() => openViewer(filename)}>
        <video
          className={`message-image${ isHidden && !showSensitiveContent ? ' blurred' : ''}`}
          preload="metadata"
        >
          <source src={`/upload/${filename}`} />
        </video>
        <FontAwesomeIcon icon={faFilm} className="video-icon" />
      </div>
    )
  case 'image':
    return !filename ? null : (
      <img
        className={`message-image${ isHidden && !showSensitiveContent ? ' blurred' : ''}`}
        src={`/thumbnail/${filename}`}
        onClick={() => openViewer(filename)}
        alt="img"
      />
    )
  default:
    return <p className="message-text">{content}</p>
  }
}

export { MessagePart }
