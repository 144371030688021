import React from 'react'
import { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrophone, faVideo } from '@fortawesome/free-solid-svg-icons'
import { getCookies } from '../Utils'

function VideoCall() {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [pc, setPc] = useState<RTCPeerConnection | null>(null)

  const startVoiceCall = () => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      .then(stream => {
        if(videoRef && videoRef.current) videoRef.current.srcObject = stream

        fetch('/api/rtc/call/', {
            credentials: 'include',
            mode: 'cors',
            method: 'POST',
            headers: {
              'X-CSRFToken': getCookies()['csrftoken'],
            },
          })
          .then(response => response.json())
          .then((json: any) => { })
      })

    /*
        const servers = undefined
        const newPc = new RTCPeerConnection(servers)
        newPc.onicecandidate = (e) => {
          console.log('Got ICE candidate: ' + JSON.stringify(e.candidate))
        }
        const audioTracks = stream.getAudioTracks()
        if (audioTracks.length > 0) {
          console.log(`Using audio device: ${audioTracks[0].label}`)
          newPc.addTrack(audioTracks[0], stream)
        }
        newPc.createOffer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: false,
          })
          .then(
            offer => newPc.setLocalDescription(offer),
            error => console.log(error),
          )
        setPc(pc)
      })
     */
  }

  return <div className="video-call">
    <div>
      <button className="button" onClick={startVoiceCall}>
        <FontAwesomeIcon icon={faMicrophone} />
      </button>
    </div>
    <div>
      <button className="button">
        <FontAwesomeIcon icon={faVideo} />
      </button>
    </div>
    <video ref={videoRef} />
  </div>
}

export { VideoCall }
