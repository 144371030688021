import React, { useEffect, useState, useRef } from 'react'
import Store, { Message, MessageFragment } from '../MyStore'
import { getCookies, jsonToMessage, hadUserActivity } from '../Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera, faEnvelope, faTimes } from '@fortawesome/free-solid-svg-icons'

function Keyboard() {
  const store = Store.useStore()
  const peerOnline = store.get('peerOnline')
  const pendingFragments = store.get('pendingFragments')
  const setPendingFragments = store.set('pendingFragments')

  const [pendingMessage, setPendingMessage] = useState('')
  const inputTextbox = useRef<HTMLTextAreaElement>(null)

  const pin = store.get('pincode')
  const sock = store.get('socket')
  const [typing, setTyping] = useState(false)

  const typingStarted = () => {
    if(!typing && peerOnline) {
      setTyping(true)
      sock?.send(JSON.stringify({'type': 'typing'}))
    }
  }

  const typingStopped = () => {
    if(typing) {
      setTyping(false)
      sock?.send(JSON.stringify({'type': 'notTyping'}))
    }
  }

  const sendMessage = () => {
    if(pendingMessage.trim() === '' && pendingFragments.length === 0) {
      return
    }

    const newMessage = {
      created: new Date(),
      content: pendingMessage || '',
      fragments: pendingFragments,
    }

    const pendingMessages = store.get('pendingMessages')
    store.set('pendingMessages')([...pendingMessages, newMessage])
    setPendingMessage('')
    setPendingFragments([])
    hadUserActivity()
    typingStopped()

    fetch('/api/messages/', {
        credentials: 'include',
        mode: 'cors',
        method: 'POST',
        headers: {
          'X-CSRFToken': getCookies()['csrftoken'],
          'Content-Type': 'application/json',
          'Authorization': `Guava-Pincode ${pin}`,
        },
        body: JSON.stringify(newMessage),
      })
      .then(response => response.json())
      .then((json: Message) => {
        const messages = store.get('messages')
        const pendingMessages = store.get('pendingMessages')
        store.set('pendingMessages')(pendingMessages.filter(x => x.content !== pendingMessage))
        store.set('messages')([...messages, jsonToMessage(json)])
      })
      .catch(e => setPendingMessage("An error occurred: " + e))

    if(inputTextbox.current) {
      inputTextbox.current.blur()
    }
    if(document.activeElement) {
      (document.activeElement as any).blur()
    }
  }

  useEffect(() => {
    const events = ['paste', 'cut', 'drop', 'change', 'keypress']
    const i = inputTextbox?.current
    const jiggle = (e: any) => {
      e.target.style.height = 'auto'
      e.target.style.height = e.target.scrollHeight + 12 + 'px'
    }

    events.map(e => i?.addEventListener(e, jiggle, false))

    return () => {
      events.map(e => i?.removeEventListener(e, jiggle, false))
    }
  }, [inputTextbox])

  const getBase64 = (file: any) => new Promise(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    })

  const attachFiles = (e: any) => {
    const file = e.target.files[0]
    getBase64(file).then(data => {
      const frag = { content: data || '', content_type: file.type }
      setPendingFragments([...pendingFragments, frag])
      e.target.value = null
    })
    hadUserActivity()
  }

  const FragmentPreview = ({content, content_type, filename}: MessageFragment) => {
    const mediaType = content_type.split('/')[0]

    switch (mediaType) {
    case 'text':
      return <p>{content}</p>
    case 'image':
      return <img src={content} alt="" />
    case 'video':
      return <p>Video...</p>
    default:
      return <p>{content}</p>
    }
  }

  const removeFragment = (frag: MessageFragment) => {
    setPendingFragments(pendingFragments.filter(x => x !== frag))
  }

  const renderPendingFragments = () =>
    <div className="pending-fragments">
      {pendingFragments.map(x =>
        <div className="card fragment-preview">
          <button className="button delete-fragment" onClick={() => removeFragment(x)}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <FragmentPreview {...x} />
        </div>)}
    </div>

  const messageInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPendingMessage(e.target.value)
    hadUserActivity()
    if(e.target.value.length > 0) {
      typingStarted()
    } else {
      typingStopped()
    }
  }

  return (
    <div className="keyboard">
      {pendingFragments.length === 0 || renderPendingFragments()}
      <div className="field has-addons">
        <div className="control">
          <textarea
            className="textarea"
            placeholder="Enter a message"
            value={pendingMessage}
            onChange={messageInput}
            ref={inputTextbox}
          />
        </div>
        <div className="keyboard-controls">
          <div className="control">
            <button className="button">
              <FontAwesomeIcon icon={faCamera} />
              <input type="file" className="file-input" onChange={attachFiles} />
            </button>
          </div>
          <div className="control">
            <button className="button is-info" onClick={sendMessage}>
              <FontAwesomeIcon icon={faEnvelope} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Keyboard }
