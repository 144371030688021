export const getCookies = (): any => document
  .cookie
  .split(';')
  .map(x => x.trim())
  .reduce((acc: any, cur: string) => {
      var parts = cur.split('=')
      acc[parts[0]] = parts[1]
      return acc
    }, {})

export const jsonToMessage = (messageJson: any) => {
  const { created, received, receipt, ...props } = messageJson
  return {
    created: new Date(created),
    received: new Date(received),
    receipt: receipt.map((y: any) => {
      const { timestamp, ...rest } = y
      return {
        timestamp: new Date(timestamp),
        ...rest
      }
    }),
    ...props
  }
}

export const jsonToMessages = (jsonResponse: any) => {
  return jsonResponse.map((x: any) => jsonToMessage(x))
}

export const setAutoLogoutTimer = (timeoutInSeconds: number) => {
  const w = window as any
  w.autoLogoutTime = timeoutInSeconds * 1000
  w.lastActivity = Date.now()

  if (w.logoutTimer) {
    clearInterval(w.logoutTimer)
  }

  w.logoutTimer = setInterval(() => {
    let elapsed = Date.now() - w.lastActivity
    if( elapsed > w.autoLogoutTime ) {
      document.body.className = 'blurred'
      window.location.reload(true)
    }
  }, 1000)
}

export const hadUserActivity = () => {
  (window as any).lastActivity = Date.now()
}
